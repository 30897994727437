
import api from 'o365.modules.data.api.js';

export default class AIDataHandler {
    url = "/api/logs";

    constructor(){}

    retrieve(pData) {
        return this.#request("retrieve", pData);
    }
    destroy(pData) {
        return this.#request("destroy", pData);
    }
    update(pData) {
        return this.#request("update", pData);
    }
    create(pData) {
        return this.#request("create", pData);
    }
    rowCount(pData) {
        return this.#request("rowcount", pData);
    }

    #request(pType, pData) {
        pData.operation = pType;
        return new Promise((resolve, reject) => {
            api.request(this.url, pData).then(data => {
                resolve(data);
            }).catch(ex => {
                o365.controls.alert(ex);
                reject(ex);
            })
        });
    }
} 
